<template>
  <PrimaryMenu />

  <Header title="Magunkról" />

  <SectionBlock>
    <SectionTitle title="A KicsiKocsik gyerekautó kölcsönző csapata..." />
    <SectionContent>
      <BRow gutter-x="4" gutter-y="4">
        <BCol sm="2">
          <BImg :src="require('@/assets/kicsikocsik-elektromos-gyerekauto-kolcsonzo-mi.png')" rounded block />
        </BCol>
        <BCol>
          <p>
            Anyukaként nagy fejtörést okoz időnként, hogy gyermekeimet életkoruknak megfelelően, időjárás függően
            változatos programok megismertetésével szórakoztassam, lehetőleg úgy, hogy közben még különböző készségeiket
            is fejlesszem.
          </p>

          <p>
            Számtalan helyen megfordultunk mi is, jobbnál jobb programokat szerveztünk a gyerekek számára, majd egy
            rendezvényen minket is utolért az elektromos kisautó őrület. Hónapokig kérlelt a Lányom, végül beadtam a
            derekam és vettünk egyet. Egy olyan eszköz került a gyerkőc birtokába, ami számos készségét fejleszti.
            Koncentráció, kitartás, koordinációs készség, térbeli tájékozódás képessége, önbizalom, szemkoordináció,
            hogy csak néhányat említsek a számos fejlesztő hatása közül. Mindemellett játékosan tanulja meg a KRESZ
            szabályait. Milyen jó is lesz, gondoltam...
          </p>

          <p>
            Attól kezdve bővült a tárolandó játékok száma, viszont elég gyorsan a többi játék sorsára jutott... már nem
            volt újdonság, így érdeklődés (és tároló) hiányában csak a nappali dísze maradt.
          </p>

          <p>
            És innen jött a <strong>KicsiKocsik Elektromos Gyerekautó Kölcsönző</strong> gondolata... bizonyára Nálatok
            is elsődleges ellenérv a tárolás. Na és arra elég drága ötlet lett, hogy csak nézegettük. A KicsiKocsik
            Elektromos Gyerekautó Kölcsönző hasonló gondolatokkal küzdő Szülők számára jött létre, így minden gyermek
            részese lehet az elektromos kisautózás adta élményeknek anélkül, hogy a vásárlás, tárolás, javítás a Szülő
            gondja lenne.
          </p>
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>

  <VideoPlayer />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import VideoPlayer from "@/components/layout/sections/SectionVideoPlayer";

export default {
  name: "About",
  title: "Magunkról",
  components: {
    VideoPlayer,
    PrimaryMenu,
    Header,
    SectionBlock,
    SectionTitle,
    SectionContent,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
