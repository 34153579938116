<template>
  <SectionBlock class="bg-light">
    <SectionContent>
      <div class="video-wrapper">
        <video class="video-element" controls>
          <source :src="require('@/assets/about-us.mp4')" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          class="video-overlay"
          @click="onClick"
          :style="{
            'background-image': `url(${require('@/assets/section-banner-bg.jpg')})`,
          }"
        ></div>
      </div>
    </SectionContent>
  </SectionBlock>
</template>
<script>
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionContent from "@/components/layout/sections/parts/SectionContent";

export default {
  name: "VideoPlayer",
  components: { SectionBlock, SectionContent },
  mounted() {
    this.init();
  },
  computed: {
    videoElement() {
      return document.querySelector(".video-element");
    },
    videoOverlay() {
      return document.querySelector(".video-overlay");
    },
  },
  methods: {
    init() {},

    onClick() {
      this.videoOverlay.classList.add("video-overlay--hidden");
      this.videoElement.play();
    },
  },
};
</script>

<style scoped lang="scss">
.video-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  background: $black;

  .video-element {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-900, 0.8);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    text-indent: -999rem;
    overflow: hidden;
    opacity: 1;
    transition: $transition-base;

    &--hidden {
      opacity: 0;
      transition: $transition-base;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
      border-radius: $border-radius-xl;
      background-color: $primary;
      transition: $transition-base;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: 5px;
      transform: translate(-50%, -50%);
      border-left: 40px solid $white;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      transition: $transition-base;
    }

    &:hover {
      cursor: pointer;

      &::before,
      &:focus::before {
        background: $primary-hover-bg;
        transition: $transition-base;
      }

      &:hover::after,
      &:focus::after {
        transition: $transition-base;
      }
    }
  }
}
</style>
